import * as tslib_1 from "tslib";
import { HttpHeaders, } from "@angular/common/http";
import { Observable } from "rxjs";
import "rxjs/add/observable/throw";
import "rxjs/add/operator/catch";
import { environment } from "src/environments/environment";
import * as i0 from "@angular/core";
import * as i1 from "@ng-toolkit/universal";
import * as i2 from "@angular/common/http";
export class RestService {
    constructor(localStorage, http) {
        this.localStorage = localStorage;
        this.http = http;
        this.baseUrl = environment.node_static_url;
        this.paymentUrl = `${environment.node_payment_url}/v1`;
        this.apiUrl = `${this.baseUrl}/v1`;
        this.apiUrl2 = `${this.baseUrl}/v2`;
        this.headers = new HttpHeaders({ "Content-Type": "application/json" });
        this.detectFaceUrl = `${this.apiUrl}/detect_face`;
        this.uploadPhotoImageUrl = `${this.apiUrl}/upload_photo_file`;
        this.uploadPassportImageUrl = `${this.apiUrl}/upload_passport_file`;
        this.uploadPassportCoverImageUrl = `${this.apiUrl}/upload_passport_cover_file`;
        this.uploadHoteldocUrl = `${this.apiUrl}/upload_hotel_file`;
        this.ocrMRZUrl = `http://10.2.100.131:30107/evisa-ocr-api/v1/mrz`;
    }
    // get maintenance url
    getMaintenance() {
        const getCaptchaUrl = `${this.apiUrl}/maintenance`;
        return this.http
            .get(getCaptchaUrl)
            .catch(this.handleError);
    }
    // get image to page apply
    getCaptchaImage() {
        const getCaptchaUrl = `${this.apiUrl}/secure/captchasrc`;
        return this.http
            .get(getCaptchaUrl, { responseType: "blob" })
            .catch(this.handleError);
    }
    // get image to page contact
    getCaptchaImageContact() {
        const getCaptchaUrl = `${this.apiUrl}/contact/captchasrc`;
        return this.http
            .get(getCaptchaUrl, { responseType: "blob" })
            .catch(this.handleError);
    }
    // get image to page contact
    getCaptchaImageReApply() {
        const getCaptchaUrl = `${this.apiUrl}/re_apply/captchasrc`;
        return this.http
            .get(getCaptchaUrl, { responseType: "blob" })
            .catch(this.handleError);
    }
    // get image to page contact
    getCaptchaImageCheckStatus() {
        const getCaptchaUrl = `${this.apiUrl}/check_status/captchasrc`;
        return this.http
            .get(getCaptchaUrl, { responseType: "blob" })
            .catch(this.handleError);
    }
    // get token to page apply
    getToken() {
        const getGenerateTokenUrl = `${this.apiUrl}/secure/generateToken`;
        return this.http.get(getGenerateTokenUrl);
    }
    // verify captcha in page apply
    verifyCaptcha(credential) {
        const verifyUrl = `${environment.featureSSID ? this.apiUrl2 : this.apiUrl}/secure/captchaVerify`;
        return this.http.post(verifyUrl, credential);
    }
    // verify captcha in page apply
    verifyPin(credential) {
        const verifyUrl = `${environment.featureSSID ? this.apiUrl2 : this.apiUrl}/secure/verify_pin`;
        return this.http.post(verifyUrl, credential);
    }
    // verify captcha in page apply
    verifyCaptchaContact(credential) {
        const verifyUrl = `${this.apiUrl}/contact/captchaVerify`;
        return this.http.post(verifyUrl, credential);
    }
    verifyTokenService(credential) {
        const verifyUrl = `${this.apiUrl}/contact/captchaVerify`;
        return this.http.post(verifyUrl, credential);
    }
    verifyToken(credential) {
        const verifyUrl = `${environment.featureSSID ? this.apiUrl2 : this.apiUrl}/secure/verify_email`;
        return this.http
            .post(verifyUrl, { token: credential })
            .catch(this.handleError);
    }
    // verify captcha in page apply
    verifyCaptchaReApply(credential) {
        const verifyUrl = `${this.apiUrl}/re_apply/captchaVerify`;
        return this.http.post(verifyUrl, credential);
    }
    // verify captcha in page apply
    verifyCaptchaCheckStatus(credential) {
        const verifyUrl = `${this.apiUrl}/check_status/captchaVerify`;
        return this.http.post(verifyUrl, credential);
    }
    verifyRetrieveApplication(credential) {
        const verifyUrl = `${this.apiUrl}/retrieve_application/captchaVerify`;
        return this.http.post(verifyUrl, credential);
    }
    handleError(error) {
        return Observable.throw("My handle err: " + error.message || "Server Error");
    }
    // Component data
    getCountry() {
        const getContryUrl = `${this.apiUrl}/component/getCountry`;
        return this.http.get(getContryUrl);
    }
    getCountryPriceCalculator() {
        const getContryUrl = `${this.apiUrl}/component/getCountryPriceCalculator`;
        return this.http.get(getContryUrl);
    }
    getCountryBirth() {
        const getContryUrl = `${this.apiUrl}/component/getCountryBirth`;
        return this.http.get(getContryUrl);
    }
    getFAQ(language) {
        const url = `${this.apiUrl}/component/getFAQ/${language}`;
        return this.http.get(url).catch(this.handleError);
    }
    getVisaType() {
        const getVisaTypeUrl = `${this.apiUrl}/component/getVisaType`;
        return this.http.get(getVisaTypeUrl);
    }
    getPortEntry() {
        const getPortEntryUrl = `${this.apiUrl}/component/getPortEntry`;
        return this.http.get(getPortEntryUrl);
    }
    getPurpose() {
        const url = `${this.apiUrl}/component/getPurpose`;
        return this.http.get(url);
    }
    getCallingCode() {
        const getCallingCodeUrl = `${this.apiUrl}/component/getCallingCode`;
        return this.http.get(getCallingCodeUrl);
    }
    detectFace(data) {
        return this.http.post(this.detectFaceUrl, data);
    }
    uploadPhotoFile(data) {
        return this.http.post(this.uploadPhotoImageUrl, data);
    }
    uploadPassportFile(data) {
        return this.http.post(this.uploadPassportImageUrl, data);
    }
    uploadPassportCoverFile(data) {
        return this.http.post(this.uploadPassportCoverImageUrl, data);
    }
    submitApplicationForm(credential) {
        const postUrl = `${this.apiUrl}/core/submit_form_application`;
        return this.http.post(postUrl, credential).catch(this.handleError);
    }
    getPersonForPayment() {
        const getPersoneUrl = `${this.apiUrl2}/core/get_person?version=2`;
        return this.http.get(getPersoneUrl);
    }
    getCountCart() {
        const url = `${this.apiUrl}/core/count_cart/`;
        return this.http.get(url);
    }
    deletePerson(credential) {
        const data = { application_id: credential };
        const postUrl = `${this.apiUrl}/core/delete_person`;
        return this.http.post(postUrl, data);
    }
    checkExistData(token) {
        const data = { token: token };
        const getPersoneUrl = `${this.apiUrl}/core/check_person/`;
        return this.http.post(getPersoneUrl, data);
    }
    getContactSubject() {
        const url = `${this.apiUrl}/component/getContactSubject`;
        return this.http.get(url).catch(this.handleError);
    }
    getCountrySubject() {
        const url = `${this.apiUrl}/component/getCountrySubject`;
        return this.http.get(url).catch(this.handleError);
    }
    // getTermCondition
    getTermConditions(language) {
        const url = `${this.apiUrl}/component/getTermConditions/${language}`;
        return this.http.get(url).catch(this.handleError);
    }
    // Language
    getLanguage() {
        const url = `${this.apiUrl}/component/language`;
        return this.http.get(url);
    }
    // check status
    checkStatus(data) {
        const url = `${this.apiUrl}/check_status/status_enquiry`;
        return this.http.post(url, data);
    }
    retrieveApplication(data) {
        const url = `${this.apiUrl}/retrieve_application/send_application_id`;
        return this.http.post(url, data);
    }
    uploadHoteldoc(uploadData) {
        return this.http.post(this.uploadHoteldocUrl, uploadData);
    }
    uploadFlightdoc(uploadData) {
        const uploadFlightdocUrl = `${this.apiUrl}/upload_flight_file`;
        return this.http.post(uploadFlightdocUrl, uploadData);
    }
    uploadThirdVisadoc(uploadData) {
        const url = `${this.apiUrl}/upload_third_visa_file`;
        return this.http.post(url, uploadData);
    }
    uploadGuarantordoc(uploadData) {
        const url = `${this.apiUrl}/upload_guarantor_file`;
        return this.http.post(url, uploadData);
    }
    uploadBankStatementdoc(uploadData) {
        const url = `${this.apiUrl}/upload_bank_statement_file`;
        return this.http.post(url, uploadData);
    }
    // Dealer
    dealerLogin(data) {
        const url = `${this.apiUrl}/dealer/login`;
        return this.http.post(url, data);
    }
    // Article
    getArticle() {
        const url = `${this.apiUrl}/component/article_list`;
        return this.http.get(url);
    }
    getArticleDetail(title, lang) {
        const url = `${this.apiUrl}/component/article_detail/${title}/${lang}`;
        return this.http.get(url);
    }
    getUserRejectedById(data) {
        const url = `${this.apiUrl}/re_apply/getStatus`;
        return this.http.post(url, data);
    }
    getApplicationInfo(application_id) {
        const data = { application_id: application_id };
        const authInfo = JSON.parse(this.localStorage.getItem(environment.localAuthenInfo));
        this.headers = new HttpHeaders({
            "Content-Type": "application/json; charset=utf-8",
            "x-access-token": authInfo.token,
        });
        const url = `${this.apiUrl}/re_apply/getApplicationInfo`;
        return this.http.post(url, data, { headers: this.headers });
    }
    checkPayment(invoice_id) {
        const url = `${this.apiUrl}/payment/check_payment`;
        return this.http.post(url, invoice_id);
    }
    checkRePay(invoice_id) {
        const url = `${this.apiUrl}/payment/check_repay`;
        return this.http.post(url, invoice_id);
    }
    payNow(invoice_id) {
        const url = `${this.apiUrl}/payment/paynow`;
        return this.http.post(url, invoice_id);
    }
    RePayNow(invoice_id) {
        const url = `${this.apiUrl}/payment/repay`;
        return this.http.post(url, invoice_id);
    }
    // Info page
    getInfo(lang) {
        const url = `${this.apiUrl}/component/info/${lang}`;
        return this.http.get(url);
    }
    // About page
    getAbout(lang) {
        const url = `${this.apiUrl}/component/about/${lang}`;
        return this.http.get(url);
    }
    getPaymentMethods() {
        const url = `${this.apiUrl}/component/payment_method`;
        return this.http.get(url);
    }
    save_partner_access(partner_id) {
        const data = { partner_id: partner_id };
        const postUrl = `${this.apiUrl}/partner/save_access`;
        return this.http.post(postUrl, data).catch(this.handleError);
    }
    // Partner
    partnerLogin(data) {
        const url = `${this.apiUrl}/partner/login`;
        return this.http.post(url, data);
    }
    getAllData(partner_id) {
        const data = { partner_id: partner_id };
        const authInfo = JSON.parse(this.localStorage.getItem("partnerAuthen"));
        this.headers = new HttpHeaders({
            "Content-Type": "application/json; charset=utf-8",
            "x-access-token": authInfo.token,
        });
        const url = `${this.apiUrl}/partner/getData`;
        return this.http.post(url, data, { headers: this.headers });
    }
    getAllVisit(partner_id) {
        const data = { partner_id: partner_id };
        const authInfo = JSON.parse(this.localStorage.getItem("partnerAuthen"));
        this.headers = new HttpHeaders({
            "Content-Type": "application/json; charset=utf-8",
            "x-access-token": authInfo.token,
        });
        const url = `${this.apiUrl}/partner/getVisit`;
        return this.http.post(url, data, { headers: this.headers });
    }
    isLoggedIn() {
        const authenInfo = JSON.parse(this.localStorage.getItem("partnerAuthen"));
        return authenInfo != null;
    }
    getExceptMessage() {
        const url = `${this.apiUrl}/component/except_message`;
        return this.http.get(url);
    }
    // Download pdf
    downloadPDF(data) {
        const url = `${this.apiUrl2}/approval-letter`;
        return this.http.post(url, data, {
            responseType: "blob",
            headers: new HttpHeaders().append("Content-Type", "application/json"),
        });
    }
    newDownloadPDF(data) {
        const url = `${this.apiUrl}/download-approval`;
        return this.http.post(url, data, {
            responseType: "blob",
            headers: new HttpHeaders().append("Content-Type", "application/json"),
        });
    }
    // Calculate price
    getCalculatePrice(country_id) {
        const data = { country_id: country_id };
        const getPriceUrl = `${this.apiUrl}/component/price_calculate`;
        return this.http.post(getPriceUrl, data);
    }
    // get status can apply
    getIsCloseApply() {
        const url = `${this.apiUrl}/component/close_apply_status`;
        return this.http.get(url);
    }
    login(form) {
        const url = `${this.apiUrl}/secure/login`;
        return this.http.post(url, form);
    }
    CheckDuplicate(form) {
        const url = `${this.apiUrl2}/check-duplicate`;
        return this.http.post(url, form);
    }
    CheckArrivalDate(form) {
        const url = `${this.apiUrl2}/check-arrival`;
        return this.http.post(url, form);
    }
    newCheckDuplicate(form) {
        const url = `${this.apiUrl}/check-duplicate`;
        return this.http.post(url, form);
    }
    newCheckArrivalDate(form) {
        const url = `${this.apiUrl}/check-arrival`;
        return this.http.post(url, form);
    }
    getFAQGroup(language) {
        const url = `${this.apiUrl2}/component/getFAQ/${language}`;
        return this.http.get(url).catch(this.handleError);
    }
    ocrMRZDetection(image) {
        return this.http.post(this.ocrMRZUrl, image);
    }
    uploadProofFileService(data) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const url = `${this.apiUrl2}/prooffile_upload`;
            try {
                const response = yield this.http.post(url, data).toPromise();
                return response;
            }
            catch (e) {
                console.log(e);
            }
        });
    }
    addUserSession(session_id) {
        const data = {
            session_id,
        };
        const url = `${this.apiUrl2}/user_session`;
        return this.http.post(url, data);
    }
    updateUserSession(data) {
        const url = `${this.apiUrl2}/update/user_session`;
        return this.http.post(url, data);
    }
    continueReviewService(data) {
        return this.http.post(`${this.apiUrl2}/continue-review`, data);
    }
    payNowV2Service(data) {
        const url = `${this.paymentUrl}/payment/paynow`;
        return this.http.post(url, data);
    }
    newBcelService(data) {
        return this.http.post(`${this.paymentUrl}/payment/bcel`, data);
    }
    getBase64ImageService(imageName, image) {
        const data = {};
        data[`${image}_name`] = imageName;
        return this.http.post(`${this.apiUrl2}/get_${image}`, data);
    }
    getMaintainance() {
        return this.http.get(`${this.apiUrl2}/maintainance`);
    }
}
RestService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RestService_Factory() { return new RestService(i0.ɵɵinject(i1.LOCAL_STORAGE), i0.ɵɵinject(i2.HttpClient)); }, token: RestService, providedIn: "root" });
